<template>
  <meta
    name="Free customizable vector template generators for use in laser cutting, engraving, CNC, die cut lines, and more. No signup required!" />
  <div class="container-main">
    <div class="container-categories">
      <div class="tag caption-1" :class="{
        'tag-inactive': current_tag !== 'All',
        'tag-active': current_tag === 'All',
      }" @click="setCurrentTag('All')">
        All
      </div>
      <div v-for="tag in allTags" :key="tag.id" class="tag caption-1" :class="{
        'tag-inactive': current_tag !== tag,
        'tag-active': current_tag === tag,
      }" @click="setCurrentTag(tag)">
        {{ tag }}
      </div>
    </div>

    <div class="container-pagination body-1">
      <div class="button-change-page-left" @click="previousPage()">
        <img :src="icon_left_arrow" class="themed-svg-icon" style="width: 10px; height: 10px; margin: auto" />
      </div>
      <div class="current-page">{{ current_page + 1 }} / {{ maxPage }}</div>
      <div class="button-change-page-right" @click="nextPage()">
        <img :src="icon_right_arrow" class="themed-svg-icon" style="width: 10px; height: 10px; margin: auto" />
      </div>
    </div>
    <div class="container-cards">
      <template-card v-for="template in paginatedTemplates" :key="template.id" :template_name="template.name"
        :experimental="template.experimental" :preview="template.preview" />
    </div>
    <div class="container-pagination">
      <a class="button-change-page body-2" style="text-decoration: none" href="https://ko-fi.com/cuttingtemplates"
        target="_blank">
        <img :src="icon_kofi" class="themed-svg-icon"
          style="width: 32px; height: 32px; margin: auto; padding-bottom: 20px" />
        <div>Buy me a coffee</div>
      </a>
    </div>
    <div class="banner-bottom body-1">
      Free customizable templates for all. No signup required!
    </div>
  </div>
</template>

<script>
import TemplateCard from "../components/TemplateCard.vue";

export default {
  name: "HomePage",
  components: {
    TemplateCard,
  },
  data() {
    return {
      templates: {},
      filtered_templates: [],
      current_tag: "All",
      current_page: 0,
      templates_per_page: 12,
      icon_right_arrow: require("../assets/right-arrows.svg"),
      icon_left_arrow: require("../assets/left-arrows.svg"),
      icon_patreon: require("../assets/patreon-512.png"),
      icon_kofi: require("../assets/ko-fi-512.png"),
    };
  },
  created() {
    this.getAllTemplates();
  },
  computed: {
    allTags() {
      let all_tags = new Set([]);

      for (let i = 0; i < this.templates.length; i += 1) {
        this.templates[i]["tags"].forEach(all_tags.add, all_tags);
      }

      all_tags = Array.from(all_tags);
      return all_tags.sort();
    },
    maxPage() {
      return Math.ceil(
        this.filtered_templates.length / this.templates_per_page
      );
    },
    paginatedTemplates() {
      let lower_limit = this.current_page * this.templates_per_page;
      let upper_limit = (this.current_page + 1) * this.templates_per_page;
      upper_limit = Math.min(upper_limit, this.templates.length);

      return this.filtered_templates.slice(lower_limit, upper_limit);
    },
  },
  methods: {
    setCurrentTag(tag) {
      this.current_tag = tag;
      this.current_page = 0;
      this.filterTemplates();
    },
    filterTemplates() {
      let relevant_templates = [];
      for (let i = 0; i < this.templates.length; i += 1) {
        if (
          this.current_tag === "All" ||
          this.templates[i]["tags"].includes(this.current_tag)
        ) {
          relevant_templates.push(this.templates[i]);
        }
      }

      this.filtered_templates = relevant_templates;
    },
    async getAllTemplates() {
      let response = await fetch(process.env.VUE_APP_API_HOST + "/templates", {
        method: "GET",
      });
      this.templates = await response.json();
      this.filterTemplates();
    },
    nextPage() {
      this.current_page = Math.min(this.maxPage - 1, this.current_page + 1);
    },
    previousPage() {
      this.current_page = Math.max(0, this.current_page - 1);
    },
  },
};
</script>

<style scoped>
.banner-bottom {
  border-bottom: 2px solid var(--secondary-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
  border-radius: 1em;
  text-align: center;
  font-style: italic;
  padding: 1em;
}

.container-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container-categories {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: auto;
  gap: 10px;
  margin-top: -2em;
  min-height: 70px;
  margin-left: -10px;
  padding-left: 10px;
  margin-right: -10px;
  padding-right: 10px;
}

.tag {
  box-sizing: border-box;
  cursor: pointer;
  padding: 1em 1em;
  border-radius: 1em;
  white-space: nowrap;
}

.tag-active {
  font-size: 0.8em;
  background: var(--main-bg-color);
  border-bottom: 2px solid var(--secondary-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.tag-inactive {
  font-size: 0.8em;
  background: var(--main-bg-color);
  border: 1px solid var(--secondary-bg-color);
}

.tag-inactive:hover {
  background: var(--main-bg-color);
  border: 1px solid var(--main-bg-color);
  border-bottom: 2px solid var(--secondary-bg-color);
  border-top: 0px;
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.container-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  min-height: 750px;
}

.container-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.button-change-page {
  flex-grow: 2;
  text-align: center;
  padding: 1em 3em;
  background: var(--primary-bg-color);
  border-radius: 1em;
  box-shadow: var(--first-shadow), var(--second-shadow);
  border-bottom: 2px solid var(--secondary-bg-color);
}

.button-change-page-left {
  flex-grow: 2;
  text-align: center;
  padding: 1em 3em;
  background: var(--primary-bg-color);
  border-radius: 1em;
  box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.02),
    -20px 0px 25px rgba(0, 0, 0, 0.04);
  border-left: 2px solid var(--secondary-bg-color);
}

.button-change-page-left:hover {
  cursor: pointer;
  background-color: var(--secondary-bg-color);
}

.button-change-page-right {
  flex-grow: 2;
  text-align: center;
  padding: 1em 3em;
  background: var(--primary-bg-color);
  border-radius: 1em;
  box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.02),
    20px 0px 25px rgba(0, 0, 0, 0.04);
  border-right: 2px solid var(--secondary-bg-color);
}

.button-change-page-right:hover {
  cursor: pointer;
  background-color: var(--secondary-bg-color);
}

.button-change-page:hover {
  cursor: pointer;
  background-color: var(--secondary-bg-color);
}

.current-page {
  padding: 0.5em 3em;
  text-align: center;
  border-radius: 10px;
}
</style>
