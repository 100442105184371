<template>
  <div class="holder">
    <a
      :to="{ name: 'templates', params: { template: template_name } }"
      style="text-decoration: none"
      class="template-card"
      :href="`/templates/${template_name}`"
    >
      <div
        class="bg"
        :style="{
          'background-image': `url(https://cuttingtemplates.nl/${template_name}-small.jpeg)`,
        }"
      ></div>
      <div class="container">
        <div>
          <p class="body-2 template-title">{{ formatTemplateName() }}</p>
          <div v-if="experimental && !preview" class="template-subtitle">
            EXPERIMENTAL
          </div>
          <div v-if="preview" class="template-subtitle">PREVIEW</div>
        </div>
        <div class="template-arrow">
          <img :src="icon_arrow" style="width: 28px; height: 18px" />
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "TemplateCard",
  props: {
    template_name: String,
    experimental: Boolean,
    preview: Boolean,
  },
  data() {
    return {
      icon_arrow: require("../assets/arrow.svg"),
    };
  },
  methods: {
    goto(location) {
      window.location.href = "/templates/" + location;
    },
    formatTemplateName() {
      let words = this.template_name.split("-");
      for (let i = 0; i < words.length; i += 1) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      return words.join(" ");
    },
  },
};
</script>

<style scoped>
.bg {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-size: contain;
  filter: var(--brightness-filter);
}

.holder {
  position: relative;
  box-sizing: border-box;
  width: 25%;
}

.holder:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

@media (max-width: 500px) {
  .holder {
    width: 50%;
  }
}

.template-card {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  left: 4%;
  top: 4%;
  width: 92%;
  height: 92%;
  border-radius: 20px;
  border-bottom: 2px solid var(--secondary-bg-color);
}

.template-card:hover {
  background: var(--main-bg-color);
  border-bottom: 4px solid var(--secondary-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.template-image {
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 9%;
  left: 9%;
  height: 82%;
  width: 82%;
}

.template-title {
  margin: 0px;
}

.template-subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: var(--main-accent-color);
}

.template-arrow {
  text-align: right;
}
</style>
